import { Link } from 'gatsby';
import React, { useEffect } from 'react';

import ContentSection from '../components/content-section';
import Layout from '../components/layout';
import MetaData from '../components/meta-data';
import { trackGoal } from '../utilities/plausible';

const NotFoundPage = (props) => {
  const title = 'Seite nicht gefunden';

  useEffect(() => {
    trackGoal('Error: 404', {
      props: { path: props.location.pathname },
    });
  });

  return (
    <Layout>
      <MetaData title={title} />

      <ContentSection>
        <h1>{title}</h1>

        <p className="mt-30">
          Die gewünschte Seite wurde leider nicht gefunden. Vielleicht hast du
          die Adresse falsch eingegeben. Überprüfe bitte die Schreibweise und
          versuche es erneut.
        </p>
        <p>
          <Link to="/">Zurück zur Startseite</Link>
        </p>
      </ContentSection>
    </Layout>
  );
};

export default NotFoundPage;
