import PropTypes from 'prop-types';
import React from 'react';

const ContentSection = ({ children, light }) => {
  return (
    <section className={'section-wrap-sm' + (light ? ' bg-light' : '')}>
      <div className="container">
        <div className="row">
          <div className="col-sm-12">{children}</div>
        </div>
      </div>
    </section>
  );
};

ContentSection.propTypes = {
  children: PropTypes.node.isRequired,
  light: PropTypes.bool,
};

export default ContentSection;
